import axios from '@core/axios';
import { BoardProfileBOD } from '@model/board-profile';
import { ReportDetail } from '@model/report-detail';
import { ReportInfo } from '@model/report-info';
import {
  ReportCorporateGovernanceSubType,
  ReportCustomersResponsibility,
  ReportEnvironmentType,
  ReportEnvironmentWaterType,
  ReportGovernanceType,
  ReportSocialType,
} from '@model/report-node';
import { ReportProgress } from '@model/response/report-progress';
import { ReportStatusResponse } from '@model/response/report-status.response';
import { ReportResponse } from '@model/response/report.response';
import { from, map, Observable } from 'rxjs';

type AddPrefix<TKey extends string, TPrefix extends string> = `${TPrefix}/${TKey}`;

type AddLaborPrefix<TKey extends string> = AddPrefix<TKey, 'laborRights'>;
type ReportLaborRightsType = AddLaborPrefix<
  | 'employeeStatistic'
  | 'employeeRemuneration'
  | 'employeeSafety'
  | 'employeeTraining'
  | 'employeeRelationship'
  | 'laborMgmtPlan'
  | 'laborMgmtPerformance'
  | 'employeeGroupingWithinOrg'
  | 'employeePvdPolic'
  | 'numberOfEmployeesSignificantChanges'
  | 'laborTargetPolicy'
  | 'laborTargetPolicy/list'
  | 'employeePvdPolicy'
>;

type AddCorporateGovernancePrefix<TKey extends string> = AddPrefix<TKey, 'corporateGovernance'>;
type ReportCorporateGovernanceType = AddCorporateGovernancePrefix<
  | 'corporateGovernancePolicy'
  | 'corporateGovernance'
  | 'presentBOD'
  | 'pastBOD'
  | 'presentAuditCommittee'
  | 'pastAuditCommittee'
  | 'presentSubCommittee'
  | 'presentTopExecutive'
>;
type AddCorporateGovernancePolicyPrefix<TKey extends string> = AddPrefix<TKey, 'corporateGovernancePolicy'>;
type ReportCorporateGovernancePolicyType = AddCorporateGovernancePolicyPrefix<
  'cgIssue' | 'cocIssue' | 'shareholderPolicy' | 'cocPolicy' | 'againstFraudAndCorruption'
>;

type AddSustainabilityPrefix<TKey extends string> = AddPrefix<TKey, 'sustainabilityPolicy'>;
type ReportSustainabilityType = AddSustainabilityPrefix<
  'sustainabilityStandard' | 'businessValueChainImage' | 'businessValueChainStakeholder'
>;

export type ReportType =
  | ReportEnvironmentType
  | ReportEnvironmentWaterType
  | ReportSocialType
  | ReportLaborRightsType
  | ReportCorporateGovernanceType
  | ReportGovernanceType
  | ReportSustainabilityType
  | ReportCorporateGovernancePolicyType
  | ReportCorporateGovernanceSubType
  | ReportCustomersResponsibility;

export class ReportService {
  public static getReportInfo(year: number): Observable<ReportInfo> {
    const url = `/report/${year}/info`;
    return from(axios.get(url)).pipe(map((d) => d.data));
  }

  public static getDraftReport<R>(id: number | string, type: ReportType, typeId?: number, params?: AnyValue): Observable<R> {
    const url = `/report/${id}/${type}/${typeId ?? ''}`;
    return from(axios.get<R>(url, params)).pipe(map((d) => d.data));
  }

  public static createDraftReport<R, V>(id: number | string, type: ReportType, values: V, params?: AnyValue): Observable<R> {
    const url = `/report/${id}/${type}`;
    return from(axios.post<R>(url, values, params)).pipe(map((d) => d.data));
  }

  public static updateDraftReport<R, V>(
    id: number | string,
    type: ReportType,
    values: V,
    typeId?: number,
    params?: AnyValue
  ): Observable<R> {
    const url = `/report/${id}/${type}/${typeId ?? ''}`;
    return from(axios.put<R>(url, values, params)).pipe(map((d) => d.data));
  }

  public static updateDraftReportOrder<R, V>(id: number | string, type: ReportType, values: V): Observable<R> {
    const url = `/report/${id}/${type}/order`;
    return from(axios.put<R>(url, values)).pipe(map((d) => d.data));
  }

  public static deleteDraftReport<R>(id: number | string, type: ReportType, typeId: number, params?: AnyValue): Observable<R> {
    const url = `/report/${id}/${type}/${typeId}`;
    return from(axios.delete<R>(url, params)).pipe(map((d) => d.data));
  }

  public static getReportDetail(reportId: number | string): Observable<ReportDetail> {
    const url = `/report/${reportId}/detail`;
    return from(axios.get(url)).pipe(map((d) => d.data));
  }

  public static getReportProgress(reportId: number): Observable<ReportProgress> {
    const url = `/report/${reportId}/progress`;
    return from(axios.get(url)).pipe(map((d) => d.data));
  }

  public static getReport(id: number | string): Observable<ReportResponse> {
    const url = `/report/${id}`;
    return from(axios.get(url)).pipe(map((d) => d.data));
  }

  public static approveReport(id: number | string): Observable<Pick<ReportResponse, 'status'>> {
    const url = `/report/${id}/approve/approve`;
    return from(axios.post(url)).pipe(map((d) => d.data));
  }

  public static rejectReport(id: number | string, reason: string): Observable<Pick<ReportResponse, 'status'>> {
    const url = `/report/${id}/approve/reject`;
    return from(axios.post(url, { reason })).pipe(map((d) => d.data));
  }

  public static getListBod(reportId: number | string, params: AnyValue): Observable<BoardProfileBOD[]> {
    const url = `/report/${reportId}/corporateGovernance/listBOD`;
    return from(axios.post(url, params)).pipe(map((d) => d.data));
  }

  public static getReportStatus(reportId: number): Observable<ReportStatusResponse> {
    const url = `/report/${reportId}/status`;
    return from(axios.get(url)).pipe(map((d) => d.data));
  }
}
