import PageLayout from '@layouts/layout/PageLayout';
import { ReportLayout } from '@layouts/layout/ReportLayout';
import LandingPage from 'pages/landing';
import { DefaultGenerics, Navigate, Route } from 'react-location';
import React from 'react';
import Auth from 'pages/auth';
import ErrorRouteFetch from '@components/error-route-fetch/ErrorRouteFetch';
import PendingElement from '@components/pending-element/PendingElement';
import getDefaultData, { DefaultData } from '@helpers/utils/get-default-data';
import { BreadcrumbRouteProps } from '@mis/sushi-tailwind-react';
import DisclaimerPage from 'pages/disclaimer';
import DisclaimerGuard from '@components/disclaimer-guard/DisclaimerGuard';
import LDPLandingPage from 'pages/ldp-landing/LDPLanding';
import AnotherUserLogin from 'pages/another-user-login/AnotherUserLogin';
import RoleGuard from '@components/role-guard/RoleGuard';
import { BackOfficeLayout } from '@layouts/layout/BackOfficeLayout';

type Breadcrumb = BreadcrumbRouteProps & {
  next?: string | null;
  back?: string | null;
};

const routers: Route<DefaultGenerics>[] = [
  {
    path: '/auth',
    element: <Auth />,
  },
  {
    path: '/ldp-landing/:asOfYear',
    element: <LDPLandingPage />,
  },
  {
    path: '/ldp-landing',
    element: <LDPLandingPage />,
  },
  {
    path: '/disclaimer',
    loader: async (): Promise<Partial<DefaultData>> => {
      return await getDefaultData();
    },
    pendingElement: <PendingElement />,
    errorElement: <ErrorRouteFetch />,
    pendingMs: 100,
    pendingMinMs: 100,
    element: (
      <PageLayout>
        <DisclaimerPage />
      </PageLayout>
    ),
  },
  {
    path: '/',
    element: (
      <PageLayout>
        <DisclaimerGuard>
          <RoleGuard>
            <LandingPage />
          </RoleGuard>
        </DisclaimerGuard>
      </PageLayout>
    ),
    loader: async (): Promise<Partial<DefaultData>> => {
      return await getDefaultData();
    },
    pendingElement: <PendingElement />,
    errorElement: <ErrorRouteFetch />,
    pendingMs: 100,
    pendingMinMs: 100,
    meta: {
      breadcrumb: () => 'home',
    },
  },
  {
    path: '/another-user-login',
    element: (
      <PageLayout>
        <AnotherUserLogin />
      </PageLayout>
    ),
  },
  {
    path: '/report/:reportId',
    loader: async ({ params: { reportId } }: AnyValue): Promise<Partial<DefaultData>> => {
      return await getDefaultData(reportId);
    },
    errorElement: <ErrorRouteFetch />,
    pendingElement: <PendingElement />,
    pendingMs: 100,
    pendingMinMs: 100,
    meta: {
      breadcrumb: (): Breadcrumb[] => {
        return [
          { label: 'home', href: '/' },
          { label: 'year', href: '/' },
        ];
      },
    },
    element: (
      <DisclaimerGuard>
        <ReportLayout />
      </DisclaimerGuard>
    ),
    children: [
      {
        path: '/reportScope',
        element: () => import('./pages/reportScope').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'reportNode.reportScope.title', next: '/environmentalMgmt', back: null, href: '/reportScope' }];
          },
        },
      },
      {
        path: '/environmentalMgmt',
        element: () => import('./pages/environmental').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [
              { label: 'reportNode.environment.breadcrumb', href: '/environmentalMgmt' },
              { label: 'reportNode.environmentalMgmt.title', next: '/energyMgmt', back: '/reportScope', href: '/environmentalMgmt' },
            ];
          },
        },
      },
      {
        path: '/energyMgmt',
        element: () => import('./pages/energy').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [
              { label: 'reportNode.environment.breadcrumb', href: '/environmentalMgmt' },
              { label: 'reportNode.energyMgmt.title', next: '/waterMgmt', back: '/environmentalMgmt', href: '/energyMgmt' },
            ];
          },
        },
      },
      {
        path: '/waterMgmt',
        element: () => import('./pages/water').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [
              { label: 'reportNode.environment.breadcrumb', href: '/environmentalMgmt' },
              { label: 'reportNode.waterMgmt.title', next: '/wasteMgmt', back: '/energyMgmt', href: '/waterMgmt' },
            ];
          },
        },
      },
      {
        path: '/wasteMgmt',
        element: () => import('./pages/waste').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [
              { label: 'reportNode.environment.breadcrumb', href: '/environmentalMgmt' },
              { label: 'reportNode.wasteMgmt.title', next: '/ghGasMgmt', back: '/waterMgmt', href: '/wasteMgmt' },
            ];
          },
        },
      },
      {
        path: '/ghGasMgmt',
        element: () => import('./pages/ghGas').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [
              { label: 'reportNode.environment.breadcrumb', href: '/environmentalMgmt' },
              { label: 'reportNode.ghGasMgmt.title', next: '/humanRights', back: '/wasteMgmt', href: '/ghGasMgmt' },
            ];
          },
        },
      },
      {
        path: '/humanRights',
        element: () => import('./pages/humanRights').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [
              { label: 'reportNode.social.breadcrumb', href: '/humanRights' },
              { label: 'reportNode.humanRights.title', next: '/laborRights', back: '/ghGasMgmt', href: '/humanRights' },
            ];
          },
        },
      },
      {
        path: '/laborRights',
        element: () => import('./pages/laborRights').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [
              { label: 'reportNode.social.breadcrumb', href: '/humanRights' },
              { label: 'reportNode.laborRights.title', next: '/corporateGovernancePolicy', back: '/humanRights', href: '/laborRights' },
            ];
          },
        },
      },
      {
        path: '/customersResponsibility',
        element: () => import('./pages/customersResponsibility').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [
              { label: 'reportNode.social.breadcrumb', href: '/humanRights' },
              {
                label: 'reportNode.customersResponsibility.title',
                next: '/societyResponsibility',
                back: '/laborRights',
                href: '/customersResponsibility',
              },
            ];
          },
        },
      },
      {
        path: '/societyResponsibility',
        element: () => import('./pages/societyResponsibility').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [
              { label: 'reportNode.social.breadcrumb', href: '/humanRights' },
              {
                label: 'reportNode.societyResponsibility.title',
                next: '/corporateGovernancePolicy',
                back: '/customersResponsibility',
                href: '/societyResponsibility',
              },
            ];
          },
        },
      },
      {
        path: '/corporateGovernancePolicy',
        element: () => import('./pages/corporateGovernancePolicy').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [
              { label: 'reportNode.governance.breadcrumb', href: '/corporateGovernancePolicy' },
              {
                label: 'reportNode.corporateGovernancePolicy.title',
                next: '/corporateGovernance',
                back: '/societyResponsibility',
                href: '/corporateGovernancePolicy',
              },
            ];
          },
        },
      },
      {
        path: '/corporateGovernance',
        element: () => import('./pages/corporateGovernance').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [
              { label: 'reportNode.governance.breadcrumb', href: '/corporateGovernancePolicy' },
              {
                label: 'reportNode.corporateGovernance.title',
                next: '/resultOfCorporateGovernance',
                back: '/corporateGovernancePolicy',
                href: '/corporateGovernance',
              },
            ];
          },
        },
      },
      {
        path: '/sustainabilityPolicy',
        element: () => import('./pages/sustainabilityPolicy').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [
              { label: 'reportNode.governance.breadcrumb', href: '/corporateGovernancePolicy' },
              {
                label: 'reportNode.sustainabilityPolicy.title',
                next: null,
                back: '/resultOfCorporateGovernance',
                href: '/sustainabilityPolicy',
              },
            ];
          },
        },
      },
      {
        path: '/sendToApprover',
        element: () => import('./pages/sendToApprover').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'reportNode.sendToApprover.breadcrumb', next: null, back: null, href: '/sendToApprover' }];
          },
        },
      },
      {
        path: '/approveReport',
        element: () => import('./pages/approveReport').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'reportNode.approveReport.title', next: null, back: null, href: '/approveReport' }];
          },
        },
      },
      {
        path: '/resultOfCorporateGovernance',
        element: () => import('./pages/resultOfCorporateGovernance').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [
              { label: 'reportNode.governance.breadcrumb', href: '/corporateGovernancePolicy' },
              {
                label: 'reportNode.resultOfCorporateGovernance.title',
                next: '/sustainabilityPolicy',
                back: '/corporateGovernance',
                href: '/resultOfCorporateGovernance',
              },
            ];
          },
        },
      },
      {
        element: <Navigate to="/" />,
      },
    ],
  },
  {
    element: <Navigate to="/" />,
  },
  {
    path: '/backOffice',
    loader: async (): Promise<Partial<DefaultData>> => {
      return await getDefaultData();
    },
    element: (
      <DisclaimerGuard>
        <RoleGuard>
          <BackOfficeLayout />
        </RoleGuard>
      </DisclaimerGuard>
    ),
    children: [
      {
        path: '/monitoringReport',
        element: () => import('./pages/backOffice/monitoringReport').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'backOffice.monitoring_report.title', href: '/monitoringReport' }];
          },
        },
      },
      {
        path: '/discountConditionReport',
        element: () => import('./pages/backOffice/discountConditionReport').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'backOffice.discount_condition_report.title', href: '/discountConditionReport' }];
          },
        },
      },
      {
        element: <Navigate to="/" />,
      },
    ],
  },
];

export default routers;
